import { Dish } from 'models';
import { applyDiscount, convertPrice, convertPriceTo } from 'util/number';
import { calculateExtraPrice, calculateTotalPrice } from 'util/priceCalculator';
import shortId from 'shortid';
import Big from 'big.js';
import { OrderPizzaToppingInput, DishType } from 'generated/types';
import { IDiscount } from 'generated/custom';

class CartItem extends Dish {
  readonly uuid: string = shortId.generate();

  quantity: number = 1;

  pizzaToppingGroupsOrderLineItems?: OrderPizzaToppingInput[];

  pizzaSaucesOrderLineItems?: OrderPizzaToppingInput[];

  pizzaCheeseOrderLineItems?: OrderPizzaToppingInput[];

  pizzaActiveSizeId?: string;

  pizzaActiveCrustId?: string;

  dishTypePizzaPriceToDiscount?: string;

  dishTypePizzaTotalPrice?: string;

  dishTypePizzaTotalPriceStriked?: string;

  note: string | null = null;

  isPreDiscounted: boolean = false;

  discountPercent: string | null = null;

  get getExtras() {
    const extras = [];

    if (this.extraGroups && this.extraGroups.length) {
      for (const extra of this.extraGroups) {
        const { title: extraGroupTitle, items: extraGroupItems } = extra;

        if (extraGroupItems && extraGroupItems.length) {
          const items = [];

          for (const item of extraGroupItems) {
            const { title: itemTitle, price } = item;

            const { intValue, shift } = price;

            const price1 = convertPrice(intValue, shift);

            items.push({
              price: price1,
              title: itemTitle
            });
          }

          extras.push({
            items,
            title: extraGroupTitle
          });
        }
      }
    }

    return extras;
  }

  get selectedSizeTitle() {
    const sizeText = (this.price && this.price[0] && this.price[0].size && this.price[0].size) || '';

    return sizeText;
  }

  get extraGroupsPrices() {
    const extraGroupsPrices = [];

    if (this.extraGroups && this.extraGroups.length) {
      for (const extra of this.extraGroups) {
        const { items: extraGroupItems } = extra;

        if (extraGroupItems && extraGroupItems.length) {
          for (const item of extraGroupItems) {
            const { price } = item;

            const { intValue, shift } = price;

            const price1 = convertPrice(intValue, shift);

            extraGroupsPrices.push(price1);
          }
        }
      }
    }

    return extraGroupsPrices;
  }

  get subtotalToDiscount() {
    if (this.isPreDiscounted) {
      const extraGroupsPrices = this.extraGroupsPrices;

      return calculateExtraPrice({ extrasPrice: extraGroupsPrices, quantity: this.quantity });
    } else {
      return this.subtotal;
    }
  }

  get subtotal() {
    if (this.price && this.price[0]) {
      const { intValue, shift } = this.price[0].price || { intValue: 0, shift: 0 };

      const sizePrice = convertPrice(intValue, shift);

      let basePrice = sizePrice;

      //If dish is on a discounted price
      if (this.isPreDiscounted && this.price[0].discountAmount) {
        const discountAmount = convertPriceTo(this.price[0].discountAmount, 'DOLLAR');

        const discount: IDiscount = applyDiscount(sizePrice, discountAmount);

        basePrice = discount.price;
      }

      const extraGroupsPrices = this.extraGroupsPrices;

      return calculateTotalPrice({
        extrasPrice: extraGroupsPrices,
        quantity: this.quantity,
        sizePrice: basePrice
      });
    }

    return '';
  }

  get subtotalStriked() {
    if (this.isPreDiscounted) {
      const { intValue, shift } = (this.price && this.price[0] && this.price[0].price) || { intValue: 0, shift: 0 };

      const sizePrice = convertPrice(intValue, shift);

      const extraGroupsPrices = this.extraGroupsPrices;

      return calculateTotalPrice({
        extrasPrice: extraGroupsPrices,
        quantity: this.quantity,
        sizePrice
      });
    }

    return '';
  }

  get getPoints() {
    const rewardPoints = this.rewardPoints;

    const quantity = this.quantity;

    return Big(rewardPoints)
      .times(quantity)
      .toString();
  }

  get getDishTypePizzaTotalPriceToDiscount() {
    if (this.type === DishType.Pizza && this.dishTypePizzaPriceToDiscount) {
      return Big(this.dishTypePizzaPriceToDiscount)
        .times(this.quantity)
        .toFixed(2);
    }

    return '';
  }

  get getDishTypePizzaTotalPrice() {
    if (this.type === DishType.Pizza && this.dishTypePizzaTotalPrice) {
      return Big(this.dishTypePizzaTotalPrice)
        .times(this.quantity)
        .toFixed(2);
    }

    return '';
  }

  get getDishTypePizzaTotalPriceStriked() {
    if (this.type === DishType.Pizza && this.dishTypePizzaTotalPriceStriked) {
      return Big(this.dishTypePizzaTotalPriceStriked)
        .times(this.quantity)
        .toFixed(2);
    }

    return '';
  }
}

export default CartItem;
