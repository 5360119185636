import gql from 'graphql-tag';

export const createDishMutation = gql`
  mutation createDish($input: CreateDishInput!) {
    createDish(input: $input) {
      id
      title
      category {
        title
      }
      status
      description
      price {
        size
        isDefault
        price {
          currencyCode
          intValue
          shift
        }
      }
      images {
        id
        md {
          id
          url
        }
      }
    }
  }
`;

export const updateDishMutation = gql`
  mutation updateDish($input: UpdateDishInput!) {
    updateDish(input: $input) {
      id
      title
      category {
        title
      }
      status
      description
      price {
        size
        isDefault
        price {
          currencyCode
          intValue
          shift
        }
      }
      images {
        id
        md {
          id
          url
        }
      }
    }
  }
`;

export const deleteDishMutation = gql`
  mutation deleteDish($input: DeleteDishInput!) {
    deleteDish(input: $input)
  }
`;

export const createReviewMutation = gql`
  mutation createReview($input: CreateReviewInput!) {
    createReview(input: $input)
  }
`;
