import { gql } from 'apollo-boost';
import { Dish } from 'graphql/fragments/dish.fragments';

export const getDishesQuery = gql`
  query getDishes($activeDishesInput: GetDishesInput!, $hiddenDishesInput: GetDishesInput!) {
    activeDishes: getDishes(input: $activeDishesInput) {
      edges {
        node {
          ...Dish
        }
      }
    }

    hiddenDishes: getDishes(input: $hiddenDishesInput) {
      edges {
        node {
          ...Dish
        }
      }
    }
  }
  ${Dish}
`;

export const getActiveDishesQuery = gql`
  query getActiveDishes($input: GetDishesInput!) {
    getDishes(input: $input) {
      edges {
        node {
          ...Dish
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${Dish}
`;

export const getDishQuery = gql`
  query getDish($input: GetDishInput!) {
    getDish(input: $input) {
      ...Dish
      restaurant {
        id
        hasStripeAccount
        lokobeeFee
      }
      extraGroups {
        id
        title
        minSelect
        maxSelect
        multiQtyPerItem
        isOptional
        items {
          id
          title
          disabled
          price {
            intValue
            shift
            currencyCode
          }
        }
      }
      pizzaCrust {
        id
        title
        description
        isDefault
        sizes {
          id
          title
          enableHalfHalf
        }
        prices {
          size {
            id
          }
          price {
            intValue
            shift
          }
        }
      }
      pizzaToppingGroups {
        id
        title
        description
        items {
          id
          title
          description
          preset
          prices {
            id
            size {
              id
              title
              enableHalfHalf
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
              partPrice {
                intValue
                shift
              }
            }
          }
        }
      }
      pizzaSauce {
        id
        title
        description
        items {
          id
          title
          description
          preset
          prices {
            id
            size {
              id
              title
              enableHalfHalf
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
              partPrice {
                intValue
                shift
              }
            }
          }
        }
      }
      pizzaCheese {
        id
        title
        description
        items {
          id
          title
          description
          preset
          prices {
            id
            size {
              id
              title
              enableHalfHalf
            }
            densities
            densityPrices {
              density
              price {
                intValue
                shift
              }
              partPrice {
                intValue
                shift
              }
            }
          }
        }
      }
    }
  }
  ${Dish}
`;

export const getDishCategoriesQuery = gql`
  query getDishCategoriesOfRestaurant($input: GetDishCategoriesOfRestaurantInput!) {
    getDishCategoriesOfRestaurant(input: $input) {
      id
      title
      dishes {
        status
      }
    }
  }
`;

export const getAllActiveDishesQuery = gql`
  query getAllActiveDishes($input: GetAllDishesInput!) {
    getAllDishes(input: $input) {
      ...Dish
    }
  }
  ${Dish}
`;

export const getRewardItemsQuery = gql`
  query getRewardItems($input: GetRewardItemsInput!) {
    getRewardItems(input: $input) {
      ...Dish
    }
  }
  ${Dish}
`;
