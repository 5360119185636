import { defaultTimeZone } from 'generated/custom';
import { DeliverySetting, OpsSetting, Restaurant } from 'generated/types';
import { map, sortBy } from 'lodash';
import { toTaxRateStr, convertPrice } from 'util/number';

class RestaurantModel implements Restaurant {
  opsSetting?: OpsSetting | null | undefined;
  lokobeeFee!: boolean;
  enablePizzaCompiler?: boolean | null | undefined;
  deliverySetting?: DeliverySetting | null | undefined;
  takeoutWaitMinutes?: number | null | undefined;
  orderEmails?: string[] | null | undefined;
  enableDishNote!: boolean;
  takeoutOnlinePaymentTypes?: Array<import('../generated/types').PaymentType> | null | undefined;
  deliveryRadius?: number | null | undefined;
  __typename?: 'Restaurant' | undefined;
  subName?: string | null | undefined;
  reportEmails?: string[] | null | undefined;
  addressNote?: string | null | undefined;
  storeCard?: import('../generated/types').Image | null | undefined;
  isOnVacation?: boolean | null | undefined;
  issueRewardPoint?: boolean | null | undefined;
  takeoutPaymentSetting?: import('../generated/types').PaymentSetting | null | undefined;
  diningPaymentSetting?: import('../generated/types').PaymentSetting | null | undefined;
  deliveryPaymentSetting?: import('../generated/types').PaymentSetting | null | undefined;
  hasStripeAccount?: boolean | null | undefined;
  stripeAccount?: string | null | undefined;
  enableTakeoutOrder!: boolean;
  enableDiningOrder!: boolean;
  enableDeliveryOrder!: boolean;
  enableTakeoutOnSitePayment!: boolean;
  enableTakeoutOnlinePayment!: boolean;
  cryptoCouponEnabled!: boolean;
  cryptoCouponExists!: boolean;
  id!: string;

  createdAt: any;

  updatedAt: any;

  lokobeePlan!: import('../generated/types').LokobeePlan;

  type?: import('../generated/types').RestaurantType | null | undefined;

  i18n?: import('../generated/types').I18NSetting | null | undefined;

  name?: string | null | undefined;

  description?: string | null | undefined;

  announcement?: string | null | undefined;

  email?: string | null | undefined;

  phone?: string | null | undefined;
  virtualPhone?: string | null | undefined;
  address?: import('../generated/types').Address | null | undefined;

  bizHours?: Array<import('../generated/types').BizHour> | null | undefined;

  taxRate?: import('../generated/types').TaxRate | null | undefined;

  logo?: import('../generated/types').Image | null | undefined;

  images?: Array<import('../generated/types').MultiSizeImage> | null | undefined;

  owner?: import('../generated/types').LokobeeUser | null | undefined;

  staff?: Array<import('../generated/types').BizEmployee> | null | undefined;

  tables?: Array<import('../generated/types').Table> | null | undefined;

  onlineStatus!: import('../generated/types').RestaurantStatus;

  isOpen?: boolean | null | undefined;

  currencyCode?: import('../generated/types').CurrencyCode | null | undefined;

  dishDisplaySetting?: import('../generated/types').RestaurantDishDisplaySetting | null | undefined;

  rewardSetting?: import('../generated/types').RewardSetting | null | undefined;

  isLokobeePartner!: boolean;

  coupons?: Array<import('../generated/types').Coupon> | null | undefined;

  yelpWriteReviewLink?: string | null | undefined;
  googleWriteReviewLink?: string | null | undefined;

  rateCount: number = 0;

  rateScore: number = 0;

  get getId() {
    return this.id;
  }
  get getName() {
    return this.name || '';
  }

  get getSubName() {
    return this.subName || '';
  }

  get getDescription() {
    return this.description || '';
  }

  get getLogo() {
    if (this.logo) {
      return this.logo.url;
    }

    return null;
  }

  get getDisplayImage() {
    if (this.images && this.images.length) {
      if (this.images[0].md) {
        return this.images[0].md.url;
      }
      return null;
    }
    return null;
  }
  get getDisplayImagePreview() {
    if (this.images && this.images.length) {
      if (this.images[0].preview) {
        return this.images[0].preview.url;
      }
      return null;
    }
    return null;
  }

  get getGalleryImage() {
    if (this.images && this.images.length) {
      return this.images;
    }
    return null;
  }

  get getAnnouncement() {
    return this.announcement || '';
  }

  get getAddress() {
    if (this.address) {
      return this.address;
    }

    return null;
  }

  get getPhone() {
    return this.phone;
  }

  get getBizHours() {
    return this.bizHours;
  }

  get getTimezone() {
    return this.i18n ? this.i18n.timezone || defaultTimeZone : defaultTimeZone;
  }

  get getLatLng() {
    if (this.address) {
      const { lat, lng } = this.address;

      return {
        lat,
        lng
      };
    }

    return null;
  }

  get getTaxRate() {
    if (this.taxRate) {
      const { intValue, shift } = this.taxRate;

      return toTaxRateStr(intValue, shift);
    }

    return null;
  }

  get getDeliverySettings() {
    if (this.deliverySetting) {
      const { waitMinutes, freeDeliveryAmount, minDeliveryAmount, fees, deliveryRadius } = this.deliverySetting;

      let fees1 = map(fees, ({ fee, mile }) => {
        return {
          fee: parseFloat(convertPrice(fee.intValue, fee.shift)),
          mile
        };
      });

      fees1 = sortBy(fees1, 'mile');

      return {
        waitMinutes,
        freeDeliveryAmount: freeDeliveryAmount ? parseFloat(convertPrice(freeDeliveryAmount?.intValue, freeDeliveryAmount?.shift)) : null,
        minDeliveryAmount: minDeliveryAmount ? parseFloat(convertPrice(minDeliveryAmount?.intValue, minDeliveryAmount?.shift)) : null,
        fees: fees1,
        deliveryRadius
      };
    }

    return {
      waitMinutes: null,
      freeDeliveryAmount: null,
      minDeliveryAmount: null,
      fees: null,
      deliveryRadius: null
    };
  }
}

export default RestaurantModel;
