import { gql } from 'apollo-boost';

export const getOrderByOrderIdQuery = gql`
  query getOrderByOrderId($input: GetOrderByOrderIdInput!) {
    getOrderByOrderId(input: $input) {
      id
      status
      orderNumber
      note
      deliveryNote
      orderPhoneNumber
      orderEmail
      expectTime
      dropOffInstruction
      deliveryAddress {
        text
      }
      deliveryDistance
      seller {
        id
        phone
        name
        logo {
          url
        }
        subName
        i18n {
          timezone
        }
      }
      paymentType
      type
      createdAt
      redeemPoints
      adjustReason
      isDiscounted
      subTotal {
        intValue
        shift
      }
      taxRate {
        intValue
        shift
      }
      total {
        intValue
        shift
      }
      tip {
        intValue
        shift
      }
      tax {
        intValue
        shift
      }
      deliveryFee {
        intValue
        shift
      }
      couponDiscount {
        intValue
        shift
      }
      lokobeeFee {
        intValue
        shift
      }
      adjustAmount {
        intValue
        shift
      }
      totalAfterAdjust {
        intValue
        shift
      }
      coupon {
        id
        code
      }
      deliveryRequest {
        doordashClassicPayload {
          delivery_tracking_url
          dasher {
            first_name
            last_name
            dasher_phone_number_for_customer
          }
        }
      }
      deliveryTimelineBoons {
        id
        createdAt
        deliveryTrackingId
        status
        title
        msg
        cancelledReason
        cancelledReasonCode
        delayReason
        delayedReasonCode
      }
      deliveryTimelineDoordashClassic {
        id
        createdAt
        status
        title
        msg
        cancelledReason
        cancelledReasonCode
        delayReason
        delayedReasonCode
      }
      timeLines {
        id
        createdAt
        status
        title
        msg
      }
      items {
        dishId
        note
        count
        dishType
        isAlcohol
        isReward
        isDiscounted
        points
        dishCategoryTitle
        dishTitle
        dishDescription
        dishPrice {
          price {
            intValue
            shift
          }
          size
          discountAmount
        }
        dishExtras {
          count
          dishExtraGroupId
          dishExtraGroupTitle
          dishExtraItem {
            title
            price {
              intValue
              shift
            }
          }
        }

        pizzaBasePrice {
          id
          size {
            id
            title
          }
          price {
            intValue
            shift
          }
          discountAmount
        }
        pizzaCrust {
          crustId
          title
          price {
            intValue
            shift
          }
        }
        pizzaSauces {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaCheeses {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }

        pizzaToppings {
          toppingId
          toppingGroupId
          toppingGroupTitle
          side
          toppingTitle
          toppingDescription
          toppingDensity
          toppingPrice {
            intValue
            shift
          }
          preset
        }
      }
    }
  }
`;

export const getBuyerOrdersQuery = gql`
  query getBuyerOrders($input: GetBuyerOrdersInput!) {
    getBuyerOrders(input: $input) {
      edges {
        node {
          id
          isRated
          buyer {
            displayName
          }
          createdAt
          deliveryAddress {
            text
          }
          expectTime
          items {
            dishTitle
            dishId
            count
          }
          note
          orderNumber
          deliveryProvider
          deliveryMode
          partySize
          paymentType
          seller {
            id
            name
            logo {
              url
            }
            i18n {
              timezone
            }
          }
          status
          type
          redeemPoints
          total {
            intValue
            shift
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        endCursor
      }
    }
  }
`;

export const getBuyerGroceryOrders = gql`
  query getBuyerGroceryOrders($input: GetGroceryBuyerOrdersInput!) {
    getBuyerGroceryOrders(input: $input) {
      edges {
        node {
          id
          isRated
          buyer {
            displayName
          }
          createdAt
          deliveryAddress {
            text
          }
          expectTime
          items {
            title
            itemId
            count
          }
          note
          orderNumber
          paymentType
          seller {
            id
            name
            logo {
              url
            }
            timezone
          }
          status
          type
          redeemPoints
          total
          deliveryProvider
        }
      }
      pageInfo {
        hasNextPage
        hasPrevPage
        endCursor
      }
    }
  }
`;

export const getGroceryOrderByOrderId = gql`
  query getGroceryOrderByOrderId($input: GetGroceryOrderByOrderIdInput!) {
    getGroceryOrderByOrderId(input: $input) {
      id
      status
      expectTime
      orderNumber
      note
      lokobeeFee
      totalTax
      deliveryNote
      adjustReason
      isDiscounted
      redeemPoints
      seller {
        id
        name
        logo {
          url
        }
        address {
          text
        }
        phone
      }
      deliveryAddress {
        text
      }
      timeLines {
        id
        createdAt
        msg
        status
      }
      doordashDeliveryTimeline {
        id
        createdAt
        reason
        status
      }
      deliveryDistance
      buyer {
        phoneNumber
        email
        displayName
      }
      orderEmail
      orderPhoneNumber
      paymentType
      type
      createdAt

      subTotal
      total
      tip
      deliveryFee
      couponDiscount
      lokobeeFee
      adjustAmount
      totalAfterAdjust
      coupon {
        code
      }
      items {
        itemId
        title
        upcCode
        size
        categoryId
        subCategoryId
        brand
        price
        status
        description
        taxMode
        count
        isReward
        isDiscounted
        isAlcohol
      }
    }
  }
`;
