import React, { useState, useCallback, useEffect, useContext } from 'react';
import { Button, Typography, Box, Backdrop, Divider } from '@material-ui/core';
import { map, isEmpty, chain, some } from 'lodash';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { Stepper } from '@lokobee/lokobee-ui';
import { useStyles } from './style';
import { useTablesServicesQuery } from 'graphql/hooks/tables.hooks';
import { convertPrice } from 'util/number';
import { useCreateTableServiceOrderMutation } from 'graphql/hooks';
import { useStore } from 'store';
import { useSnackbar } from 'notistack';
import { AuthContext } from 'fbase/authContext';
import { useLocation } from 'react-router-dom';
import { useAlert } from 'hooks';

interface IProps {
  restaurantId: string;
}

const TableService: React.FC<IProps> = ({ restaurantId }) => {
  const location = useLocation();

  const classes = useStyles();

  const query = new URLSearchParams(location.search);

  const urlTableParamValue = query.get('table');

  const { currentUser } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const snackbar = useSnackbar();

  const { lokoAlert } = useAlert();

  const {
    state: { tableNumber, cartRestaurant }
  } = useStore();

  const [tableServiceData, setTableServiceData] = useState<Array<{ id: string; title: string; quantity: number; price: string }>>([]);

  const { createTableServiceOrder } = useCreateTableServiceOrderMutation();

  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const { tableServices } = useTablesServicesQuery({
    restaurantId
  });

  useEffect(() => {
    const data = map(tableServices, ({ id, title, price }) => {
      const { intValue, shift } = (price && price[0] && price[0].price) || { intValue: '', shift: '' };

      return {
        id,
        title: title || '',
        quantity: 0,
        price: convertPrice(intValue, shift)
      };
    });

    setTableServiceData(data);
  }, [tableServices]);

  const onQuantityChange = useCallback(
    ({ id, newValue }: { id: string; newValue: number }) => {
      const updatedData = map(tableServiceData, ({ id: serviceId, title, quantity, price }) => {
        if (id === serviceId) {
          return { id: serviceId, title, quantity: newValue, price: '0.00' };
        }

        return { id: serviceId, title, quantity, price };
      });

      // Disable the submit button if any service has 0 quantity
      setSubmitDisabled(!some(updatedData, 'quantity'));
      setTableServiceData(updatedData);
    },
    [tableServiceData]
  );

  const onCancel = (value: boolean) => () => {
    // Reset all table service quantity to initial value.
    setTableServiceData(map(tableServiceData, ({ quantity, ...rest }) => ({ quantity: 0, ...rest })));
    setOpen(value);
    setSubmitDisabled(true);
  };

  const onSubmit = async () => {
    try {
      setSubmitDisabled(true);

      const items = chain(tableServiceData)
        .filter(({ quantity }) => quantity !== 0)
        .map(({ id, quantity }) => {
          return {
            dishId: id,
            dishPriceId: '',
            count: quantity
          };
        })
        .value();

      await createTableServiceOrder({
        variables: {
          input: {
            sellerBizId: restaurantId,
            items,
            tableName: tableNumber
          }
        }
      });

      snackbar.enqueueSnackbar('Message sent.', {
        variant: 'success'
      });

      setOpen(false);

      // Reset all table service quantity to initial value.
      setTableServiceData(map(tableServiceData, ({ quantity, ...rest }) => ({ quantity: 0, ...rest })));
    } catch {
      setSubmitDisabled(false);

      lokoAlert('Something went wrong :) Try again.');
    }
  };

  if ((isEmpty(tableNumber) || cartRestaurant?.id !== restaurantId) && !urlTableParamValue) {
    return null;
  }

  const onButtonClick = () => {
    const isDisabled = !currentUser;

    if (isDisabled) {
      lokoAlert('You must LOGIN to enable Table Services');
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Button className={classes.root} variant="contained" color="primary" startIcon={<RestaurantIcon />} onClick={onButtonClick} fullWidth={true}>
        <Typography variant="subtitle2" color="textSecondary">
          Table Services
        </Typography>
      </Button>
      <Backdrop open={open} className={classes.backdrop}>
        <Box height="max-content" className={classes.customTipContainer}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box marginBottom={1}>
              <Typography variant="h6">Table Services</Typography>
            </Box>
            <Divider />
            <Box overflow="scroll" flex={1} paddingTop={1}>
              {map(tableServiceData, ({ id, title, quantity }) => {
                return (
                  <Box key={id} display="flex" marginBottom={2}>
                    <Box flex={1}>
                      <Typography>{title}</Typography>
                    </Box>
                    <Stepper value={quantity} onChange={(newValue) => onQuantityChange({ id, newValue })} />
                  </Box>
                );
              })}
            </Box>
            <Box paddingY={1} textAlign="right">
              <Button variant="outlined" color="default" onClick={onCancel(false)}>
                Cancel
              </Button>
              <Box component="span" marginLeft={1}>
                <Button variant="contained" color="primary" disabled={isSubmitDisabled} onClick={onSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    </>
  );
};

export default TableService;
