import React, { useState, useEffect } from 'react';
import { map, isNull } from 'lodash';
import { FormControlLabel, Radio, Typography, FormControl, RadioGroup } from '@material-ui/core';
import { useStyles } from './style';
import FormLabelText from '../FormLabelText';
import { applyDiscount, convertPrice, convertPriceTo } from 'util/number';
import { IDiscount } from 'generated/custom';

interface IProps {
  prices: any;
  isDiscounted: boolean;
  rewardPoints: number | string;
  onChange: (index: number) => void;
  currency?: 'USD' | 'POINT';
}

const Size: React.FC<IProps> = ({ prices, isDiscounted, rewardPoints, onChange, currency = 'USD' }) => {
  const classes = useStyles();

  const [dishPrices, setDishPrices] = useState<any[]>();

  const [active, setActive] = useState<number>(0);

  const [defaultIndex, setDefaultIndex] = useState<number | null>(null);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setActive(index);

    onChange(index);
  };

  useEffect(() => {
    if (!dishPrices && prices && prices.length) {
      if (isNull(defaultIndex)) {
        for (let i = 0; i < prices.length; i++) {
          if (prices[i].isDefault) {
            setActive(i);
            setDefaultIndex(i);
            onChange(i);
          }
        }
      }
      setDishPrices(prices);
    }
  }, [defaultIndex, dishPrices, onChange, prices]);

  if (dishPrices && dishPrices.length) {
    const output = map(dishPrices, ({ price, isDefault, size, discountAmount }, index) => {
      // const [{ text: title }] = size || [{ text: '' }];
      const title = size || '';
      const { intValue, shift } = price;

      const itemPrice = convertPrice(intValue, shift);

      let sizePrice = itemPrice;

      let sizeDiscount,
        strikedPrice = '';

      //If dish is on a discount
      if (isDiscounted && discountAmount) {
        const discount: IDiscount = applyDiscount(itemPrice, convertPriceTo(discountAmount, 'DOLLAR'));

        sizePrice = discount.price;

        sizeDiscount = discount.percentage;

        strikedPrice = itemPrice;
      }

      return (
        <FormControlLabel
          classes={{
            label: classes.formControlLabel
          }}
          key={title}
          control={<Radio key={title} name={title} checked={active === index} onChange={(e) => onChangeHandler(e, index)} color="primary" />}
          label={<FormLabelText title={title} rewardPoints={rewardPoints} price={sizePrice} discount={sizeDiscount} strikedPrice={strikedPrice} currency={currency} />}
        />
      );
    });

    return (
      <>
        <Typography variant="body1">Price</Typography>
        <Typography variant="caption">Size</Typography>
        <FormControl className={classes.formControl} required={true} component="fieldset">
          <RadioGroup>{output}</RadioGroup>
        </FormControl>
      </>
    );
  }

  return null;
};

export default Size;
