import gql from 'graphql-tag';

export const Dish = gql`
  fragment Dish on Dish {
    id
    title
    category {
      id
      title
    }
    status
    isAlcohol
    isDiscounted
    isReward
    rewardPoints
    rateScore
    rateCount
    description
    type
    tags
    price {
      id
      size
      isDefault
      price {
        currencyCode
        intValue
        shift
      }
      discountAmount
    }
    images {
      id
      preview {
        id
        url
      }
      md {
        id
        url
      }
      xl {
        id
        url
      }
    }
    pizzaBasePrice {
      id
      size {
        id
        title
        enableHalfHalf
      }
      price {
        intValue
        shift
      }
      isDefault
      discountAmount
    }
  }
`;
