import {
  Order,
  Maybe,
  OrderType,
  OrderStatus,
  LokobeeUser,
  Restaurant,
  DeliveryProviders,
  DeliveryStatusBoons,
  DeliveryOrderBoonsTimeLine,
  DeliveryOrderDoordashClassicTimeLine,
  OrderLineItem,
  DeliveryRequest,
  CryptoCouponInfo
} from 'generated/types';
import { convertPrice } from 'util/number';

interface lineItem extends Omit<OrderLineItem, 'dishTitle'> {
  dishTitle: string;
}

class OrderModel implements Readonly<Order> {
  __typename?: 'Order' | undefined;
  id!: string;
  requestId!: string;
  createdAt: any;
  updatedAt: any;
  orderNumber!: string;
  type!: OrderType;
  status!: OrderStatus;
  buyer!: LokobeeUser;
  buyerUid!: string;
  seller!: Restaurant;
  items?: import('../generated/types').OrderLineItem[] | null | undefined;
  note?: string | null | undefined;
  timeLines?: Maybe<import('../generated/types').OrderTimeLine>[] | null | undefined;
  deliveryTimelineBoons?: Maybe<DeliveryOrderBoonsTimeLine>[] | undefined;
  deliveryTimelineDoordashClassic?: Maybe<DeliveryOrderDoordashClassicTimeLine>[] | undefined;
  expectTime?: any;
  deliveryRequest?: DeliveryRequest;
  deliveryAddress?: import('../generated/types').Address | null | undefined;
  deliveryDistance?: number | null | undefined;
  deliveryFee?: import('../generated/types').MoneyAmount | null | undefined;
  deliveryNote?: string | null | undefined;
  deliveryStatus?: Maybe<DeliveryStatusBoons> | undefined;
  paymentType?: import('../generated/types').PaymentType | null | undefined;
  taxRate?: import('../generated/types').TaxRate | null | undefined;
  subTotal?: import('../generated/types').MoneyAmount | null | undefined;
  tax?: import('../generated/types').MoneyAmount | null | undefined;
  total?: import('../generated/types').MoneyAmount | null | undefined;
  tip?: import('../generated/types').MoneyAmount | null | undefined;
  onlinePaymentFee?: import('../generated/types').MoneyAmount | null | undefined;
  serviceFee?: import('../generated/types').MoneyAmount | null | undefined;
  tableName?: string | null | undefined;
  partySize?: number | null | undefined;
  couponDiscount?: import('../generated/types').MoneyAmount | null | undefined;
  coupon?: CryptoCouponInfo | null | undefined;
  lokobeeFee?: import('../generated/types').MoneyAmount | null | undefined;
  redeemPoints?: number | null | undefined;
  adjustAmount?: import('../generated/types').MoneyAmount | null | undefined;
  adjustReason?: string | null | undefined;
  totalAfterAdjust?: import('../generated/types').MoneyAmount | null | undefined;
  orderPhoneNumber?: string | null | undefined;
  orderEmail?: string | null | undefined;
  isDiscounted?: boolean | null | undefined;
  isRated?: boolean | null | undefined;
  deliveryProvider?: DeliveryProviders;
  dropOffInstruction?: string | null | undefined;

  get getItems() {
    return this.items?.map((item) => {
      const { dishTitle } = item;

      return {
        ...item,
        dishTitle: dishTitle || ''
      };
    });
  }

  get getItemsWithoutDuplicateRewardItems() {
    const items: lineItem[] = [];

    const itemObj: any = {};

    this.items?.forEach((item) => {
      const { dishTitle, dishId } = item;

      if (!itemObj[dishId || '']) {
        items.push({
          ...item,
          dishTitle: dishTitle || ''
        });

        itemObj[dishId || ''] = true;
      }
    });

    return items;
  }

  get getRestaurantLogo() {
    const { logo } = this.seller;

    const { url } = logo || { url: '' };

    return url;
  }

  get getRestaurantName() {
    const { name } = this.seller;

    return name || '';
  }

  get getTotal() {
    if (this.total?.intValue && this.total?.shift) {
      return convertPrice(this.total?.intValue, this.total?.shift);
    }
    return '0.00';
  }
}

export default OrderModel;
