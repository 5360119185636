import Big from 'big.js';
import { CryptoUnit, IDiscount } from 'generated/custom';
import { map } from 'lodash';

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return null;
};

export const convertPrice = (value: number, shift: number) => {
  let strValue = `${value}`;

  let prefix = '';

  let prefixZero = strValue.length + shift - 1;

  while (prefixZero < 0) {
    prefix += '0';
    prefixZero += 1;
  }

  strValue = prefix + strValue;

  return (strValue = strValue.slice(0, shift) + '.' + strValue.slice(shift));
};
export function toTaxRateStr(intValue: number, shift: number): string {
  let strValue = `${intValue}`;

  let prefix = '';
  let prefixZero = strValue.length + shift - 1;
  while (prefixZero < 0) {
    prefix += '0';
    prefixZero += 1;
  }
  strValue = prefix + strValue;
  let part1 = strValue.slice(0, shift);
  let part2 = strValue.slice(shift);
  return `${part1}.${part2}`;
}

interface IPrice {
  size: string;
  isDefault: boolean;
  price: {
    currencyCode: string;
    intValue: number;
    shift: number;
  };
}

interface IOut {
  title: string;
  price: number | string;
  isDefault: boolean;
}

type Output = IOut[] | undefined;

export const parsePrice = (arg: [IPrice]): Output => {
  if (arg && arg.length) {
    const output: IOut[] = map(arg, ({ size, price, isDefault }) => {
      const title = size || '';
      const { intValue, shift } = price;

      return {
        title,
        price: convertPrice(intValue, shift),
        shift,
        isDefault
      };
    });

    return output;
  }

  return undefined;
};

export const applyDiscount = (price: string, discountAmount: string): IDiscount => {
  const discountedPrice = Big(price)
    .sub(discountAmount)
    .toFixed(2);

  const discountedPercentage = Big(discountAmount)
    .div(price)
    .mul(100)
    .round()
    .toString();

  return {
    price: discountedPrice,
    percentage: discountedPercentage
  };
};

export const convertPriceTo = (price: string | number, convertTo: 'DOLLAR' | 'CENT') => {
  if (price) {
    if (convertTo === 'DOLLAR') {
      return Big(price)
        .div(100)
        .toFixed(2);
    }

    if (convertTo === 'CENT') {
      return Big(price)
        .mul(100)
        .toFixed(2);
    }
  }

  return '0.00';
};

// export const convertToCCTokens = (ccTokenData: CcTokenOutput) => {
//   if (ccTokenData && ccTokenData.tokens && ccTokenData.cryptoUnit) {
//     if (ccTokenData.cryptoUnit === 'TWEI') {
//       return (ccTokenData.tokens / Math.pow(10, 6)).toString() + ' CC';
//     }
//   }
//   return '';
// };

export const convertToCCTokens = (tokens: number, cryptoUnit: string) => {
  if (tokens && cryptoUnit === CryptoUnit.Qwei) {
    return (tokens / Math.pow(10, 3)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Twei) {
    return (tokens / Math.pow(10, 6)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Gwei) {
    return (tokens / Math.pow(10, 9)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Wei) {
    return (tokens / Math.pow(10, 18)).toString() + ' CBT';
  }

  if (tokens && cryptoUnit === CryptoUnit.Cc) {
    return tokens + ' CBT';
  }

  return '0 CBT';
};
